import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, /* INLINES, MARKS */ } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import readingTime from 'reading-time';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import ReactPlayer from 'react-player';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Tags from '../components/tags';
import * as styles from './blog-post.module.css';

const renderOptions = {

    renderNode: {

        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            
            const { gatsbyImageData, description, mimeType, url } = node.data.target

            if (mimeType.match('video')) {

                return (
                    // <video src={url} preload="auto" autoplay loop playsinline webkit-playsinline x5-playsinline className={styles.video}></video>
                    <video
                        preload="auto"
                        controls
                        autoplay
                        loop
                        muted
                        playsinline
                        webkit-playsinline
                        x5-playsinline
                        controlsList="nodownload"
                        className={styles.video}
                    >
                        <source src={url} />
                    </video>
                    
                    // <ReactPlayer
                    //     width="100%"
                    //     playing
                    //     autoPlay
                    //     // muted
                    //     playsinline
                    //     loop
                    //     controls
                    //     url={url}
                    // />
                );

            }

            return (
                <GatsbyImage
                    image={getImage(gatsbyImageData)}
                    alt={description}
                    className="mb-10"
                />
            );

        },

    }

};


class ArticleTemplate extends React.Component {

    render () {

        const post = get(this.props, 'data.contentfulArticle');
        const previous = get(this.props, 'data.previous');
        const next = get(this.props, 'data.next');
        const plainTextDescription = documentToPlainTextString(JSON.parse(post.description.raw));
        const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw));
        const { minutes: timeToRead } = readingTime(plainTextBody);

        return (
            <Layout location={this.props.location}>
                <Seo
                    title={post.title}
                    description={plainTextDescription}
                    image={`https://www.huaxia-media.net${post.heroImage.resize.src}`}
                />
                <Hero
                    image={post.heroImage?.gatsbyImageData}
                    title={post.title}
                    content={post.description}
                />
                <div className={styles.container}>
                    <span className={styles.meta}>
                        {post.author?.name} &middot;{' '}
                        <time dateTime={post.rawDate}>{post.publishDate}</time> –{' '}
                        {timeToRead} minute read
                    </span>
                    <div className={styles.article}>
                        <div className={styles.body}>
                            {post.body?.raw && renderRichText(post.body, renderOptions)}
                        </div>
                        <Tags tags={post.tags} />
                        {(previous || next) && (
                            <nav>
                                <ul className={styles.articleNavigation}>
                                <li>
                                    {
                                        previous && (
                                            <Link to={`/article/${previous.slug}/`} rel="prev">
                                                ← {previous.title}
                                            </Link>
                                        )
                                    }
                                </li>
                                <li>
                                    {
                                        next && (
                                            <Link to={`/article/${next.slug}/`} rel="next">
                                                {next.title} →
                                            </Link>
                                        )
                                    }
                                </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </Layout>
        );
        
    };

}

export default ArticleTemplate;

export const pageQuery = graphql`
    query ArticleBySlug(
        $slug: String!
        $previousPostSlug: String
        $nextPostSlug: String
    ) {
        contentfulArticle(slug: { eq: $slug }) {
            slug
            title
            author {
                name
            }
            publishDate(formatString: "MMMM Do, YYYY")
            rawDate: publishDate
            heroImage {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
                resize(height: 630, width: 1200) {
                    src
                }
            }
            body {
                raw
                references {
                    # ... on ContentfulArticle {
                    #     id
                    #     slug
                    #     contentful_id
                    #     title
                    #     description {
                    #         raw
                    #     }
                    # }
                    ... on ContentfulAsset {
                        id
                        title
                        gatsbyImageData(width: 1000)
                        __typename
                        url
                        mimeType
                        contentful_id
                        description
                    }
                }
            }
            tags
            description {
                raw
            }
        }
        previous: contentfulArticle(slug: { eq: $previousPostSlug }) {
            slug
            title
        }
        next: contentfulArticle(slug: { eq: $nextPostSlug }) {
            slug
            title
        }
    }
`
